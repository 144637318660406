<template>
  <price-page1></price-page1>
  <price-page2></price-page2>
  <compare-page></compare-page>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import PricePage1 from "@/pages/Pc/PricePage1.vue";
import PricePage2 from "@/pages/Pc/PricePage2.vue";
import ComparePage from "@/pages/Pc/ComparePage.vue";
import useMeta  from 'vue-meta-info';

// Define your meta information
const metaInfo = ref({
  title: '价格页',
  meta: [
    {
      name: 'keywords',
      content: 'vue,前端技术,测试一下',
    },
    {
      name: 'description',
      content: '这是关于预渲染的价格页',
    },
  ],
});

// Use the useMeta function from vue-meta-info
useMeta(metaInfo);
</script>

