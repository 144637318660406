<template>
  <page-template  :right-header="true" id="ceshi">
    <template #header>
      <side-header >
        <template #heading-primary>MappingSpace</template>
        <template #heading-secondary>{{t('homepage.test.info')}}</template>
        <template #heading-secondary2>{{t('homepage.test.title')}}</template>
        <template #description >{{t('homepage.test.desc')}}
          <div class="replace right">
            <div class="title" style="margin-left: 30rem;">{{ t("homepage.replace") }}</div>
            <div class="li1">
              <div class="li11" style="width: 20rem;">Jira Xray/Testrail</div>
              <div class="li11" style="width: 10rem;">Testlink</div>
            </div>
            <div class="li2">
              <div class="li22" style="width: 20rem;">{{t("homepage.test.li1")}}</div>
              <div class="li22"  style="width: 10rem;">{{t("homepage.test.li2")}}</div>
            </div>
          </div>
        </template>
      </side-header>
    </template>
    <template #default>
      <img src="../../assets/imgs/ceshimain.webp" alt="" class="img">
    </template>
  </page-template>
</template>

<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
</script>

<style scoped>
.img{
  transform: translate(-30%, 42%);
  scale: 1.3 !important;
}
</style>

