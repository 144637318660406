import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
//Pc组件
import TestV from "@/TestV.vue";
import HomeView from "@/views/Pc/HomeView.vue";
import AboutView from "@/views/Pc/AboutView.vue";
import CompareView from "@/views/Pc/CompareView.vue";
import PriceView from "@/views/Pc/PriceView.vue";
//移动端组件
import MbHomeView from "@/views/Mobile/MbHomeView.vue";
import MbPriceView from "@/views/Mobile/MbPriceView.vue";
import MbAboutView from "@/views/Mobile/MbAboutView.vue";
const routes: Array<RouteRecordRaw> = [
  //Pc路由
  {
    path: "/test",
    component: TestV
  },
  {
    path: "/home",
    component: HomeView
  },
  {
    path: "/about-us",
    component: AboutView
  },
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/price",
    component: PriceView
  },
  {
    path: "/compare",
    component: CompareView
  },
  {
    path: '/funcSafety',
    component: () => import('@/views/Pc/FunSafetyView.vue'),
  },
  {
    path: '/customer_case/:pageId',
    component: () => import('@/views/Pc/CustomerCaseView.vue'),
  },
  {
    path: '/automotive_toolchain',
    component: () => import('@/views/Pc/AutomotiveToolchainView.vue'),
  },
  {
    path: '/information_security',
    component: () => import('@/views/Pc/InformationSecurityView.vue'),
  },
  
//移动端路由
  {
    path: "/mb_home",
    component: MbHomeView
  },
  {
    path: "/mb_price",
    component: MbPriceView
  },
  {
    path: "/mb_about",
    component: MbAboutView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(function () {
  window.scroll(0, 0)
})

export default router
