<template>
  <div class="page-other-img" style="background-color: #ffffff;padding-top: 15rem">
    <img :src="require(`@/assets/imgs/process_title_${lang}.webp`)" alt="" />
    <img :src="require(`@/assets/imgs/process_${lang}.png`)" alt="" />
    <img :src="require(`@/assets/imgs/vmodel_title_${lang}.webp`)" alt="" />
    <img :src="require(`@/assets/imgs/vmodel_${lang}.webp`)" alt="" />
  </div>
</template>

<script setup lang="ts">
import useLocale from "@/hooks/useLocale"; // 国际化hook
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale();
</script>

<style scoped lang="scss">
.page-other-img {
  min-height: 90vh;
  text-align: center;
  background: linear-gradient(
    180deg,
    rgba(245, 245, 252, 1) 0%,
    rgba(245, 245, 252, 0) 100%
  );
  img {
    &:nth-of-type(odd) {
      width: 40%;
      margin-bottom: 68px;
    }
    &:nth-of-type(even) {
      width: 90%;
      margin-bottom: 134px;
    }
  }
}
</style>
