import { createStore } from 'vuex'

export default createStore({
  state: {
    lang: "zh"
  },
  getters: {
    lang: (state)=>state.lang
  },
  mutations: {
    setLang(state,value){
      state.lang = value;
    }
  },
  actions: {
  },
  modules: {
  }
})
