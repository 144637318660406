<template>
  <page-template>
    <template #header>
      <side-header>
        <template #heading-primary>MappingSpace</template>
        <template #heading-secondary>{{t('homepage.requirement.info')}}</template>
        <template #heading-secondary2>{{t('homepage.requirement.title')}}</template>
        <template #description>{{t('homepage.requirement.desc')}}
          <div class="replace">
            <div class="title">{{ t("homepage.replace") }}</div>
            <div class="li1">
              <div class="li11">Polarion</div>
              <div class="li11">IBM Boors</div>
              <div class="li11">Word</div>
            </div>
            <div class="li2">
              <div class="li22">{{t("homepage.requirement.li1")}}</div>
              <div class="li22">{{t("homepage.requirement.li1")}}</div>
              <div class="li22">~0</div>
            </div>
          </div>
        </template>
        
      </side-header>
      
    </template>
    <template #default>
      <img :src="require(`@/assets/imgs/xuqiumain_${lang}.webp`)" alt="" style="scale: 1" class="s" :class="{'s__en': lang==='en'}">
    </template>
  </page-template>

</template>

<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
</script>

<style scoped lang="scss">
.s{
  scale: 1.4 !important;
  transform: translate(-17%, 28%);
  z-index: -100;

  &__en{
    transform: translate(-17%, 26%);
  }
}
</style>
