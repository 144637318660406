export default {
    topBar: { // 顶部导航栏
        homepage: '首 页',
        price: '价 格',
        aboutUs: '关于我们',
        funSafety:'功能安全',
        clientCases: '客户案例',
        automotiveToolchain: '汽车工具链',
        informationSecurity: '信息安全',
    },
    clientCase: {  //客户案例弹窗 
        oneName: '奥特佳新能源科技股份有限公司',
        twoName: '上海同驭汽车科技有限公司',
        threeName: '宁波福尔达智能科技股份有限公司'
    },
    btn: {
        btnTrail: "免费试用",
        btnDocument: "产品文档",
        tryIt: "立即体验",
        contact: "联系我们",
        join: "马上加入",
        demo: '演示版申请'
    },
    applyDialog: {
        title: '演示版申请',
        info: '填写信息后，我们将联系您为您演示',
        label:{
            name: '姓名',
            email: '企业邮箱',
            phone: '电话',
            careAbout: '主要关注软件哪些方面的功能：'
        },
        formInfo: {
            name: '请输入姓名',
            email: '请输入企业邮箱',
            emailFormatError: '企业邮箱格式错误',
            phone: '请输入电话',
            phoneFormatError: '电话号码格式错误'
        },
        confirm: '确认',
        cancel: '取消'
    },
    homepage: { // 首页
        Jumbotron: { // 顶部巨幕
            // word1: '满足ASPICE标准的',
            // word2: '一站式需求 ',
            // word3: ' 开发 ',
            // word4: ' 测试管理平台'
            word3:'关注公众号，了解更多信息',
            word:'Agile · ASPICE · AI',
            word2: '一站式需求、开发、测试管理平台',
            carIndustry: '汽车行业的国产替代首选'
        },
        cooperation: { // 合作
            title: '九大管理场景',
            requirement: '需求管理',
            workItem: '工作项管理',
            test: '测试管理',
            baseline: '基线管理',
            change: '变更管理',
            publish: '发布管理',
            onlineJudge: '在线评审',
            document: '文档管理',
            agile: '敏捷看板',
            secondTitle:'<span>AI</span> · ASPICE · Agile → MappingSpace',
            description:'MappingSpace融合了当前AIGC领域的最新进展，将大语言模型用于生成研发过程文档，大大提升了研发进度。研发工程师能够在MappingSpace中，直接生成需求、架构、测试用例等研发过程文档，并能将其直接生成研发任务，进行任务跟踪。',
            oneAI:{
                title:'AI补充',
                description:'对已有的研发过程文档，使用AI技术进行补充及完善',
            },
            twoAI:{
                title:'AI绘图',
                description:'基于架构设计文档，生成可编辑的静态架构图、动态架构图等，大幅提高架构师的工作效率',
            },
            threeAI:{
                title:'AI代码',
                description:'基于已有的详细设计文档，生成代码框架，大幅提高编码工程师的编码速度',
            }
        },
        replace: '可替代',
        requirement: { // 需求管理
            info: "需求管理",
            title: '思维导图式需求管理，天生最强追溯性',
            desc: '借助思维导图浮窗，快速查看并编辑任意任务属性；快速查看任意需求的上下文及其关联节点；借助图例分颜色显示状态、责任人等；需求编写、任务分配效率提升10倍以上。',
            li1: '数百万元',
        },
        workItem: {
            info: "工作项管理",
            title: '关联透视图与覆盖度查询',
            desc: '通过查看关联任务项的类型，实时显示任意节点的任意类型覆盖度（需求、架构、测试用例覆盖度等等），完全满足ASPICE工作项的追溯要求。',
            li1: '数十万元',
        },
        test: {
            info: "测试管理",
            title: '精准测试计划创建，自动添加测试用例',
            desc: '思维导图创建测试用例，还能通过思维导图执行测试用例\n' +
                '测试用例执行完，自动生成测试报告，还可一键导出\n' +
                '测试报告涵盖了Bug数据一览、测试用例数据一览、需求覆盖度报告、详细执行结果、API测试报告。',
            li1: '数万元',
            li2: '开源'
        },
        baseline: {
            info: "基线管理",
            title: '创建开发基线，查看基线变更记录',
            desc: '将节点加入基线之后，用户可以开始一条基线。用户可自由选择是否锁定基线，锁定后的基线无法直接变更，必须通过变更委员会的在线评审。基线内容的新增、变更和删除，都有清晰的审计记录。',
            li1: '数百万元',
            li2: '开源',
        },
        change: {
            info: "变更管理",
            title: '创建变更分支，多人强制评审',
            desc: '针对任意类型的工作项，均可以发起变更请求，并邀请变更委员会或指定人进行评审\n' +
                '定制评审通过规则，全面掌握变更影响。',
            li1: '数百万元',
        },
        publish: {
            info: "发布管理",
            title: '版本发布历史记录一览',
            desc: '创建发布版本后，版本可与Sprint关联，自动将Sprint中的issue加入到发布版本中，指定版本发布责任人\n' +
                '关联测试计划，随时查看测试计划进度，以便决定版本是否可以释放，自动出具版本发布报告。',
            li1: '开源',
        },
        onlineJudge: {
            info: "在线评审",
            title: '思维导图评审模式，高效当仁不让',
            desc: '对需求、测试用例等各类issue类型进行分发，邀请评审\n' +
                '多人同步评审，批量快速填写评审结果，定制评审通过规则，实时展示评审结果。',
            li1: '邮件',
            li2: '线下评审会',
            li3: '数天时间',
        },
        document: {
            info: "文档管理",
            title: '在线文档实时协同编辑',
            desc: '适合企业的多人协同编辑文档，形成企业流程、文档的知识库\n' +
                '一键将文档转化成思维导图，作为任务跟踪，文档视图与思维导图视图，无缝切换。',
            li1: '飞书',
            li2: '数十万元',
            li3: '数万元',
            li4: '开源',
        },
        agile: {
            info: "敏捷看版",
            title: '支持Scrum和Kanban，让敏捷开发团队更高效',
            desc: '按照思维导图、或按照责任人模式查看敏捷看板\n' +
                '任务流快速流转，自动出具冲刺报告。',
            li1: '数十万元',
        }
    },
    footer: { // 页脚
        header: {
            title: ' 汽车软件工具箱  我们期待您的加入',
            desc: "想了解更多·请咨询  support{'@'}ytdevops.com",
            button: '我要加入团队'
        },
        contact: {
            product: '产品详情',
            free: '免费试用',
            longTerm: '长期使用',
            company: '企业信息',
            aboutUs: '关于我们',
            joinUs: '加入我们',
            service: '企业服务',
            address: '企业地址',
            addLine1: '苏州市漕湖街道观塘路1号',
            addLine2: '西交大漕湖科技园B幢301室42号',
            privacyPolicy: '隐私协议',
            termsOfUse: '用户须知',
            china:'中国',
            overseas:'海外',
        },
        main: {
            title: '云体科技',
            contact: '联系我们',
            right: '版权所有：c   2022 云体科技',
            company: '主办单位：苏州云体科技有限公司',
            icp: 'ICP备案号：苏ICP备2021051249号-1',
            record: '苏公网安备 32050702011070号'
        }
    },
    price: { // 价格
        Jumbotron: {
            title: '软件优势',
            desc: '·汽车软件工具箱·以DevOps为中心·提高开发测试运维效率·'
        },
        // words: {
        //     word1: '满足',
        //     word2: 'For the automobile industry',
        //     word3: 'Ultra strong',
        //     word4: 'In traceability via mind map',
        //     word5: 'Relationship diagram',
        //     word6: 'Monitoring coverage',
        //     word7: 'Baseline management',
        //     word8: 'Baseline change audit',
        //     word9: 'Online review',
        //     word10: 'Real-time record of review results'
        // },
        compare: {
            item1: '是否满足汽车行业ASPICE标准',
            item2: '思维导图结构·最强追溯性',
            item3: '关联透视图',
            item4: '需求Word视图',
            item5: '基线管理',
            item6: '分支变更管理',
            item7: '在线评审',
            item8: '测试用例管理'
        },
        infoCard: {
            bar1: '满足汽车行业ASPICE标准',
            bar2: '超强追溯性 高阶思维导图',
            bar3: '关联透视图 覆盖度监测',
            bar4: '基线管理 基线变更审计',
            bar5: '在线评审 实时评审记录'
        },
        mid: {
            title: '权威安全认证·20项特权·全汽车人才团队',
            desc: '满足ASPICE标准的一站式研发管理平台'
        },
        heightspe:{
            height1:'30',
            height2:'30',
        },
        priceCard: {
            people: '适用人群',
            feature: '产品特性',
            version: {
                free: '免费体验版',
                enterprise: '超值企业版',
                selfHosting: '私有部署版'
            },
            onTrial:{
                Trial1:'适用：10人及以下',
                Trial2:'适用：10人及以上',
                Trial3:'适用：50人及以上团队',
            },
            userNumber: {
                number1: "10人及以下",
                number2: "10人及以上",
                number3: '50人及以上团队'
            },
            priceValue: {
                price1: "0元",
                price2: "/人月",
                price3: "五折",
                price4: "尝鲜价: ",
                price5: "96元"
            },
            featureList: {
                featureTitle: '全网特权',
                item1: '托管方式',
                item2: '适用用户数量',
                item3: '存储空间',
                item4: '文件层次限制',
                item5: '用户权限管理',
                item6: '批量导入导出Excel',
                item7: '变更规则设置',
                item8: '评审规则设置',
                item9: '项目模板'
            },
            projectList:{
                item1:'甘特图',
                item2:'V模型全景图',
                item3:'自动化工作流',
                item4:'知识库',
                item5:'AI机器人',
                onePro:'1个项目',
            },
            userList: {
                userTitle: '用户数量与价格',
                item1: '10人',
                item2: '50人',
                item3: '100人',
                item4: '250人',
                item5: '500人',
                item6: '1000人',
                item7: '>1000人'
            },
            peoplePrice:{
                item1:'58元/人月',
                item2:'55元/人月',
                item3:'50元/人月',
                item4:'44元/人月',
                item5:'38元/人月',
            },
            level:'三 层',
            capacity:'1 GB',
            cloud: '云托管',
            notLimited: '不限',
            contactUs: "联系我们",
            free: '免费体验版',
            priceTemplate: '元/人月',
            price1: '永久免费',
            notProvide: '不提供',
            plz: '请联系我们',
            private:'为您打造私人部署',
            hot: "热门"
        },
        priceContrast: {
            function: '功能',
            ease: '易学性',
            price: '价格',
            contrastname: '对比',
            contrast: {
                li1: '是否支持AI生成',
                li2: '是否满足汽车行业ASPICE标准',
                li3: '思维导图结构·最强追溯性',
                li4: 'V模型全景追溯报表',
                li5: 'Word文档导入导出',
                li6: '在线架构图绘制',
                li7: '基线管理',
                li8: '变更管理',
                li9: '在线评审/评审数据报表',
                li10: '测试用例管理',
                li11: '学习成本',
                li12: '以私有化部署版本估计',
            },
            polarion: {
                li1: '无评审数据报表',
                li2: '非常高，流程重。建议私有化部署试用后，亲自评估易用性',
                li3: '非常高',
            },
            JiraAndConfluence: {
                li1: '额外购买插件',
                li2: '二次开发',
                li3: '额外购买插件',
                li4: '适中，但系统配置管理成本高，需要专门的系统配置管理员',
                li5: '中等',
            },
            mappingSpace: {
                li1: '简单易学，基于思维导图的系统工程（Mind mapping based systems engineering)',
                li2: '中等'
            }
        }
    },
    aboutUs: { // 关于我们
        main: {
            title: '苏州云体科技有限公司',
            desc: '         云体科技是一家诞生于汽车行业的科技公司，专注于开发面向汽车行业的云端DevOps产品，以此帮助车企快速数字化转型。企业数字化，不仅包括了研发数字化，还包括整车智造数字化、售后运营数字化。未来的车企，研发、生产、售后运营的数据，将会在一个云端平台上，高效流通。',
            desc2: '——云体科技'
        },
        values: { // 价值观
            title: '我们的价值观',
            sincerity: '真诚',
            vision: '远见',
            collaboration: '协作',
            selfDrive: '自驱'
        },
        recruiting: { // 招聘
            title: '快来加入我们吧',
            engineering: 'Engineering工程',
            frontend: '前端软件工程师',
            backend: '后端软件工程师',
            design: 'Design设计',
            UI: 'UI设计师',
            devops: 'devops运维工程师',
            button: '马上加入'
        },
        cooperations: "合作伙伴"
    },
    funSafety:{
        hara:{
            info:'危害分析及风险评估（HARA）',
            title:'智能计算功能安全等级',
            desc1:'预设HARA分析案例，助您轻松上手',
            desc2:'基于HAZOP方法，深度探索功能故障与危害',
            desc3:'智能计算ASIL安全等级，明确安全目标',
            desc4:'思维导图展现HARA全貌',
        },
        dfmea:{
            info:'DFMEA',
            title:'新版七步法，实时跟踪改进措施',
            desc1:'预设DFMEA案例，零基础也能上手',
            desc2:'基于思维导图，智能填充结构、功能、失效分析,高效便捷',
            desc3:'SOD自动计算，洞察措施优先级',
            desc4:'实时追踪改进措施，清晰展示数据报表',
        },
        fta:{
            info:'故障树分析（FTA）',
            title:'自动计算FTA各类指标',
            desc1:'自动计算FTA核心指标，洞察系统脆弱点',
            desc2:'预设FTA分析案例，助您快速入门',
            desc3:'基于思维导图，展现清晰的FTA全景',
            desc4:'自动计算最小割集、最小径集、结构重要度、顶事件发生概率，提升效率',
        }
    },
    client: { // 客户
        customerPage1: {
            title: {
                aotecar: '汽车热管理整体解决方案',
                TongyuAutomobile: '线控底盘关键技术领跑者',
                Fuerda: '高端汽车智能光电系统',
            },
            content: {
                aotecar: '借助MappingSpace研发管理系统，奥特佳完成了项目数据的整体线上迁移，满足严苛的欧洲客户要求。',
                TongyuAutomobile: '借助MappingSpace研发管理系统，同驭汽车实现了线控底盘功能安全项目的高效管理，从而满足ISO26262提出的标准要求。',
                Fuerda: '借助MappingSpace研发管理系统，实现了对IBM Doors软件的国产替代，并大大超出了原有工具的使用效率和使用体验。',
            }
        },
        customerPage2: {
            title: {
                aotecar: '关于奥特佳',
                TongyuAutomobile: '关于同驭汽车',
                Fuerda: '关于福尔达',
            },
            content1: {
                aotecar: '奥特佳新能源科技股份有限公司是一家为汽车热管理提供整体解决方案的企业，主营业务是汽车热管理系统技术开发、产品生产及销售，主要包含汽车空调压缩机、汽车空调系统、发动机散热、新能源汽车电池热管理等业务。公司的主要产品是汽车空调压缩机、汽车空调系统及相关技术方案。',
                TongyuAutomobile: '同驭汽车科技是中国汽车智能底盘系统一级供应商，专注于“新一代线控底盘核心技术”的研发和产业化，为智慧出行保驾护航。同驭汽车成立于2016年，是同济大学重点孵化企业、国家高新技术企业、国家专精特新小巨人企业、上海市专精特新企业、上海市科技小巨人 (培育)企业。总部和研发中心设立在上海，拥有上海嘉定、江西宜春两大生产基地，已建成年产能150万套的智能制造中心。',
                Fuerda: '宁波福尔达智能科技股份有限公司自1995年成立以来，坚持“以技术引领市场、以创新追求发展”的经营方针，现拥有优秀的专业技术研发人才，并具备对光、机、电、软硬件智能集成控制系统产品、高精尖模具、智能装备（自动化装配、智能在线检测生产线等）的自主创新设计开发、制造、配套服务整合的能力。',
            },
            content2: {
                aotecar: '公司全资子公司南京奥特佳新能源科技有限公司和牡丹江富通汽车空调有限公司均从事汽车空调压缩机的研发、生产和销售。南京奥特佳是全世界领先的涡旋式汽车空调压缩机生产商，牡丹江富通是国内自主品牌活塞式压缩机领军企业。',
                TongyuAutomobile: '同驭汽车核心团队自2012年起自主研发线控底盘核心技术，在该领域有着卓越的研发实力和深厚的技术积淀。产品覆盖线控电子液压制动系统(EHB)、集成式线控电子液压制动系统(iEHB)、电子驻车制动系统(EPB)、防抱死制动系统(ABS) 、电子稳定性控制系统(ESC) 等智能制动系列产品。',
                Fuerda: '公司在慈溪、宁波、上海设有三个研发中心和五个制造基地；在德国设有子公司及研发中心，开展与海外整车厂同步设计开发、市场营销服务等工作；2021年在罗马尼亚筹建生产基地，确保欧洲及全球供货的能力，进一步开拓海外市场。',
            },
            content3: {
                aotecar: '公司附属公司空调国际集团是技术领先的汽车空调系统生产商，在美、欧、亚等大洲均有业务，服务通用、福特、捷豹-路虎等世界知名汽车生产厂商，在中国市场和其他新兴市场国家也拥有广泛客户。目前公司人员规模8000余人，公司拥有自主知识产权，发明专利158项，公司通过不断的研发积累，逐步形成自身的核心技术。',
                TongyuAutomobile: '同驭已为80多家知名客户配套100余款车型。是全球极少数具备智能制动产品量产能力的公司之一。同驭的使命是让汽车更安全、更舒适、更智慧。',
                Fuerda: '公司建立了完善的管理体系，通过了IATF16949、ISO45001、ISO14001体系认证。福尔达曾先后被评为“国家重点高新技术企业”和“国家火炬计划重点高新技术企业”、“浙江省绿色企业”、“浙江省高新技术企业研究开发中心”、 “浙江省省级企业研究院”、“浙江省高新技术企业创新能力百强”等称号。已连续十年荣获慈溪市十强企业，2018年荣获慈溪市“亩均论英雄”中第一名，2021年荣获宁波市汽车产业亩均效益领跑企业和浙江省亩均效益领跑企业。',
            },
            card1: {
                title: '挑战1',
                content1: {
                    aotecar: '线下Excel文档难以建立准确的追溯性，需要花费大量的时间，去解决追溯性和一致性的问题。',
                    TongyuAutomobile: '线控制动系统属于ASIL D等级的功能安全部件，因此需要按照功能安全的流程进行开发',
                    Fuerda: 'ASPICE标准要求的文档和流程特别多，如何满足汽车快速迭代的要求。',
                },
                content2: {
                    aotecar: '思维导图式需求管理，V模型视图展示追溯性，提高管理效率',
                    TongyuAutomobile: '支持HARA、FTA和DFMEA等功能安全分析方法',
                    Fuerda: '敏捷与ASPICE融合，提效同时也可保证质量',
                }
            },
            card2: {
                title: '挑战2',
                content1: {
                    aotecar: '通过查阅word中的版本升级记录表格，无法真正了解需求的历史修改记录。',
                    TongyuAutomobile: '线下存在大量的研发文档，需要尽快迁移到线上工具中，实现数字化管理。',
                    Fuerda: 'IBM Doors学习门槛很高，引入MappingSpace这个新工具是否会有更高的学习门槛？如何替代Doors。',
                },
                content2: {
                    aotecar: '随时查看需求修改历史记录，架构图历史版本可回滚',
                    TongyuAutomobile: 'Word、Excel批量导入，搭好研发数字化基础',
                    Fuerda: '基于思维导图的系统工程，全局思维尽在掌握',
                }
            },
            card3: {
                title: '挑战3',
                content1: {
                    aotecar: '针对过程文件的评审记录，以及评审之后建立问题的追踪历史，需额外维护更多的文件，及建立更为复杂的追溯性。',
                    TongyuAutomobile: '客户项目太多，如何解决项目复用的问题？项目复用之后，追溯性是否需要重新建立？',
                    Fuerda: '项目管理任务繁重，并且面临着多项目并行，能否使用工具，替代一部分项目经理的工作。',
                },
                content2: {
                    aotecar: '多人实时评审，自动生成评审记录及评审数据报表，提高评审效率',
                    TongyuAutomobile: '项目复用及追溯性继承，平台项目与车型项目并行开发',
                    Fuerda: '甘特图工时统计，实时出具数据报表',
                }
            },
            learn: '了解更多'
        },
        customerPage3: {
            solve: '问题解决思路及工具场景落地：',
            next: '下一个',
            previous: '上一个',
            card1: {
                title: {
                    aotecar: '思维导图式需求管理，V模型视图展示追溯性，提高管理效率',
                    TongyuAutomobile: '支持HARA、FTA和DFMEA等功能安全分析方法',
                    Fuerda: '敏捷与ASPICE融合，提效同时也可保证质量',
                },
                content: {
                    aotecar: '甲方客户维护了较多线下流程文档（系统需求规格说明书、测试规格说明书、跟踪矩阵，均为Excel格式）。在规格说明书中，每条需求存在数十列待填信息，需维护系统需求ID、干系人需求ID、需求组ID等，用于建立需求之间的追溯性；在跟踪矩阵文件中，维护全部的系统需求、系统架构、软件需求、软件架构、软件详设，每张表中均需要维护ID号及建立追溯。',
                    TongyuAutomobile: '集成式电子液压制动系统iEHB，集成了EHB、EPB、ESC三大制动产品，可实现高品质的基础制动、线控制动、线控驻车、稳定性控制等功能，并可作为底盘域控制器全面接管线控底盘。但iEHB作为自动驾驶场景中的关键部件，具有非常高的功能安全等级，因此需要进行功能安全产品认证。',
                    Fuerda: '敏捷开发强调快速迭代、适应变化和持续交付，而ASPICE则在安全和质量要求方面有非常严格的规定。如果不融合ASPICE，敏捷团队在汽车行业可能面临的问题：',
                    li1: '- 符合性问题：不能满足行业规范要求，影响产品认证和上市；',
                    li2: '- 安全风险：可能无法确保产品的安全和可靠性，难以做到对软件生命周期的全方位管理，如质量保证、终身管理等；',
                    li3: '- 客户信任缺失：客户对敏捷开发出的软件系统的信心缺失，特别是对那些可能影响生命安全的系统。',
                },
                li1: {
                    aotecar: '基于甲方客户的字段要求，在MappingSpace中轻松配置各类型字段；',
                    TongyuAutomobile: 'HARA、FTA和DFMEA分析模块，作为MappingSpace工具的一部分，因此功能安全分析过程和设计过程紧密结合；',
                    Fuerda: '将线下的Word、Excel一键导入到MappingSpace中，形成原子化、条目化管理，使用Scrum和Kanban的敏捷开发模式；',
                },
                li2: {
                    aotecar: 'MappingSpace提供了Excel和Word两种导入方式，快速将线下文档线上化，每一条需求均建立全局唯一的ID；',
                    TongyuAutomobile: '将DFMEA的优化措施，直接与设计任务关联起来，而无需考虑打通不同工具，节省了大量的人力成本和工具购买成本。',
                    Fuerda: '建立需求、架构、详细设计、测试用例之间，原子级别的追溯性；',
                },
                li3: {
                    aotecar: '以思维导图的视角，展示需求的层级关系，并建立需求之间的追溯性；',
                    Fuerda: '使用MappingSpace中原生的基线管理、变更管理、在线评审的功能，满足ASPICE 全生命周期的流程管理。',
                },
                li4: {
                    aotecar: '以V模型视图，展示全局的追溯性，并自动生成V模型跟踪矩阵',
                }
            },
            card2: {
                title: {
                    aotecar: '随时查看需求修改历史记录，架构图历史版本可回滚',
                    TongyuAutomobile: 'Word、Excel批量导入，搭好研发数字化基础',
                    Fuerda: '基于思维导图的系统工程，全局思维尽在掌握',
                },
                content: {
                    aotecar: '在word时代，需求的修改历史记录，往往通过在word中增加“历史修改记录”表格来完成，但真正修改了哪些细节，或者出于什么原因需要修改，都无从得知；另一种方案，则是通过保存多个版本的word、excel文件，但是人工比较多个版本的差异，效率太低。最终导致了需求变更的不可查阅。',
                    TongyuAutomobile: '在工具进入之前，研发团队将大量的过程文档保存在线下。这种做法导致了一些管理上的缺陷。首先，线下存储过程文档导致信息不易获取和共享。团队成员需要花费大量时间来搜索和获取所需文档，延迟项目进展。其次，由于无法实时更新和编辑线下文档，团队成员可能会错过最新的变更和更新，从而影响项目的准确性和一致性。',
                    Fuerda: '已经使用IBM Doors产品很多年了，但发现该产品的缺陷越来越明显：价格昂贵，产品易用性不好，用户界面比较陈旧且不直观，新用户学习起来可能比较困难，与其他开发工具的集成有时可能复杂，特别是跟非IBM的产品。我们希望引入新工具来替代这款产品，但是我们担心引入新工具会进一步增加工具链的复杂度，使得工具更加难以落地。',
                },
                li1: {
                    aotecar: '需求中每个字段的修改，都有详细的历史记录，包括修改人、修改时间、修改内容；',
                    TongyuAutomobile: '将线下的word、excel文档，批量导入到MappingSpace中，快速实现了需求的条目化；',
                    Fuerda: 'MappingSpace采用了基于思维导图的系统工程（MMBSE）概念，与MBSE如出一辙，它最大的优势就是直观、易学，只要掌握了思维导图的基本操作，就能在MappingSpace上大力发挥自己的优势；',
                },
                li2: {
                    aotecar: 'MappingSpace中内置了架构图绘图工具drawio，作为全球最受欢迎的开源UML绘图工具，拥有远超越visio的功能特性，并且支持架构图历史记录回滚。',
                    TongyuAutomobile: '线上的条目化需求，也可以重新导出成word或excel，便于和第三方客户进行信息共享。',
                    Fuerda: '在工具导入阶段，云体科技通过培训、答疑等方式，和客户一起探索。云体科技拥有非常资深的汽车领域工具链专家，同时，网上有众多云体科技专家录制的工具操作视频，以及撰写的专业技术文章，帮助客户落地。',
                },
            },
            card3: {
                title: {
                    aotecar: '多人实时评审，自动生成评审记录及评审数据报表，提高评审效率',
                    TongyuAutomobile: '项目复用及追溯性继承，平台项目与车型项目并行开发',
                    Fuerda: '甘特图工时统计，实时出具数据报表',
                },
                content: {
                    aotecar: '当需求被写出来之后，需要经历评审。在甲方客户内部，需求的评审非常严格，需要包含需求的评审过程、评审条目，以及评审完之后的修改过程，需要有过程记录。使用线下工具，评审记录和修改记录，都在邮件中，难以建立起有效的追溯性。',
                    TongyuAutomobile: '项目经理同时管理多个车型项目的开发，过程文档的重复度很高，之前过程文档一直在线下管理，未能建立完整的追溯性，新车型项目开始时，直接整体复制原项目的所有过程文档，改动量大，且改动后的设计，无法知晓是否应用到其他车型项目；项目经理需要同时处理多家客户的需求，和客户沟通的方式主要采取邮件沟通、excel表格等，效率较低。',
                    Fuerda: '作为项目管理中最重要的工具之一，甘特图非常重要。但我们一直没有碰到合适的甘特图工具，能够与我们的研发管理系统结合到一起。我们希望研发设计任务，只需要被创建一遍，就可以同时被加入到甘特图里面，而不是重复创建。同时我们希望在甘特图里，跟踪每个人的实际时间投入和理论估计的差别，并且能通过数据报表，实时反馈这种差距。',
                },
                li1: {
                    aotecar: '将甲方客户的checklist，直接在MappingSpace的“评审配置”处轻松进行配置；',
                    TongyuAutomobile: '我们建议客户改变原有的独立项目开发模式，转而同时维护平台项目和车型项目，因此我们给客户制订了两套方案，供用户选择，通过最大程度的复用平台项目的过程文档，从而减小项目管理过程中的工作量，客户最终选择了第二套方案并实施，工作效率提高了50%；',
                    Fuerda: 'MappingSpace中的甘特图完美契合了客户的需求，可以轻松地将各类设计任务、项目计划、bug等，加入到甘特图中；',
                },
                li2: {
                    aotecar: '用户自定义评审规则、评审人和评审结束时间；',
                    TongyuAutomobile: '通过建立客户账户，并限制客户在MappingSpace系统中的权限，从而实现与客户的信息传递，保证了信息格式的一致性和时效性，在一个系统中实现对所有客户的问题管理。',
                    Fuerda: '甘特图既可以填写项目经理的预期完成时间，也可以填写工程师的实际所用工时；',
                },
                li3: {
                    aotecar: '自动统计评审结果，自动出具评审数据报表。',
                    Fuerda: '实时、并且自动地出具甘特图的数据报表，任何时候都能支持数据统计。',
                },
            }
        }
    },
    toolchain: {  // 汽车工具链
        toolchainPage1: {
            name: '汽车工具链',
            content: 'MappingSpace为您带来更全面、更懂汽车的研发管理工具链',
        },
        toolchainPage2: {
            title: 'MappingSpace和他的朋友们',
            content: 'MappingSpace是一款专门为汽车行业打造的研发管理软件。而汽车行业涉及的工具众多，因此我们也积极与一些高效工具进行打通，为您带来更全面、更懂汽车的研发管理工具链。',
            systemRequirement: '系统需求分析',
            systemArchitecture: '系统架构设计',
            SWRequirement: '软件需求分析',
            SWArchitecture: '软件架构设计',
            SWDetailedDesign: '软件详细设计',
            coding: '编码',
            SWUnitVerification: '软件单元测试',
            SWIntegrationTest: '软件集成测试',
            SWQualificationTest: '软件合格性测试',
            systemIntegrationTest: '系统集成测试',
            systemQualificationTest: '系统合格性测试',
        },
        partner: {
            eolink: {
                content: '协助厂商研发团队高效管理 API 文档，并基于 API 文档帮助前后端与测试团队协作，达成快速发起 API 测试、通过 MockAPI协助前后端对接、提供API测试报告输出的能力。',
                content1: '通过与MappingSpace打通，接口设计文档和API接口直接关联，并可在MappingSpace中直接查看API测试报告。',
                li1: '版本管理、差异对比、变更通知，让 API 文档真正可用！',
                li2: '强大的自定义请求校验与响应，便于前后端对接和集成测试等场景！',
                li3: '提供详细的测试报告，包括返回头部、返回结果、性能时间分析等内容！',
                li4: '全面覆盖swagger、postman等API开发管理工具，依托Mappingspace实现工具一体化！',
            },
            feyshTechnology: {
                content1: {
                    title: '使用Corax帮您提升软件质量与安全性，集成MappingSpace满足汽车行业合规需求',
                    content: '软件研发团队可以在流水线上集成Corax的自动代码扫描能力，第一时间发现代码中潜在的BUG与安全漏洞，用极小的代价在软件上线前实现修复。通过与MappingSpace集成，用户可以在MappingSpace查看漏洞扫描报告。',
                },
                content2: {
                    title: '内嵌行业规范 满足合规需求',
                    content: 'Corax内置了MISRA C/C++、AUTOSAR C++、CERT C/C++、CWE、OWASP等汽车行业常用代码质量规范与安全标准，帮助研发团队实现ISO 26262、ISO 21434合规。',
                },
                content3: {
                    title: '兼容GPU编程规范 提升智驾软件稳定性',
                    content: '除了传统的嵌入式研发环境与代码，Corax同样支持对CUDA等异构编程模型进行分析，帮助研发团队有效的提升智能驾驶软件的质量与安全性。',
                },
                content4: {
                    title: '开放接口 实现研发流水线快速集成',
                    content: 'Corax采用模块化设计，代码分析与数据分析能力解耦。研发团队可以使用Corax提供的多种API接口完成流水线的无缝集成，实现在不同研发流程中对代码质量与安全进行监控和管理。',
                }
            },
            gitlab: {
                content1: {
                    title: '顶级的开源顶目',
                    li1: "源代码开放透明，提高代码质量和安全性",
                    li2: '3,300+代码贡献者和10,000+社区贡献者，繁荣的开发者生态',
                    li3: '12年选代 139+版本，保持产品的持续创新',
                },
                content2: {
                    title: '广泛的应用',
                    li1: "10万+私有部署企业用户",
                    li2: '3,000万+注册用户',
                    li3: 'Saas 每月支持数百万个项目和数十万用户',
                    li4: '67% DevOps 私有部署全球市场份额',
                },
                content3: {
                    title: '与MappingSpace高效打通',
                    li1: "通过与MappingSpace集成，用户将代码提交、CICD结果，直接关联到研发过程文档",
                    li2: '提高项目管理效率流程，实现开发任务与代码闭环',
                },
                content4: {
                    title: 'AI赋能',
                    li1: "贯穿软件开发全生命周期的AI能力",
                    li2: '隐私优先的企业级AI服务',
                    li3: '开放透明的AI功能演进路线图',
                },
            }
        }
    },
    informationSecurity: {   // 信息安全
        title: '威胁分析与风险评估（TARA）',
        title1: '满足WP.29-R155信息安全法规',
        li1: '内置WP.29-R155威胁列表和缓解措施数据库，您的私人信息安全问题识别专家',
        li2: '基于思维导图，快速识别信息安全资产',
        li3: '基于SFOP，自动计算危害影响',
        li4: '快速导出TARA分析全局清单'
    }
}
