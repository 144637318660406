<template>
  <home-page></home-page>
  <page-process></page-process>
  <cooperation-page @item-click="handleItemClick"></cooperation-page>
  <page-xuqiu v-if="selectedItem === 'xuqiu'"></page-xuqiu>
  <page-gongzuo v-if="selectedItem === 'gongzuo'"></page-gongzuo>
  <page-ceshi v-if="selectedItem === 'ceshi'"></page-ceshi>
  <page-jixian v-if="selectedItem === 'jixian'"></page-jixian>
  <page-biangeng v-if="selectedItem === 'biangeng'"></page-biangeng>
  <page-fabu v-if="selectedItem === 'fabu'"></page-fabu>
  <page-pingshen v-if="selectedItem === 'pingshen'"></page-pingshen>
  <page-wendang v-if="selectedItem === 'wendang'"></page-wendang>
  <page-minjie v-if="selectedItem === 'minjie'"></page-minjie>
  <video-page></video-page>
</template>

<script setup lang="ts">
import HomePage from "@/pages/Pc/HomePage.vue";
import VideoPage from "@/pages/Pc/VideoPage.vue";
import CooperationPage from "@/pages/Pc/CooperationPage.vue";
import PageProcess from "@/pages/Pc/PageProcess.vue";
import PageXuqiu from "@/pages/Pc/PageXuqiu.vue";
import PageGongzuo from "@/pages/Pc/PageGongzuo.vue";
import PageCeshi from "@/pages/Pc/PageCeshi.vue";
import PageJixian from "@/pages/Pc/PageJixian.vue";
import PageBiangeng from "@/pages/Pc/PageBiangeng.vue";
import PageFabu from "@/pages/Pc/PageFabu.vue";
import PagePingshen from "@/pages/Pc/PagePingshen.vue";
import PageWendang from "@/pages/Pc/PageWendang.vue";
import PageMinjie from "@/pages/Pc/PageMinjie.vue";

import {ref,nextTick} from 'vue'
const selectedItem = ref('xuqiu');
const handleItemClick = (item: string) => {
  selectedItem.value = item;
  nextTick(()=>{
    document.querySelector(`#${item}`)!.scrollIntoView({
    behavior: "smooth"
  })
  })
}
</script>
