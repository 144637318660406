<template>
  <section class="the-nav">
    <div class="logo-box">
      <img src="../../assets/imgs/logo.png" alt="logo" />
    </div>
    <div class="nav-bar">
      <h1>8H Auto Devops</h1>
      <div class="menu" :class="{'menu-en': lang === 'en'}">
        <router-link to="/home">
          <div class="menu__item" @mouseenter="selected = 1" @mouseleave="selected = 0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="18"
              height="17"
              viewBox="0 0 18 17"
              fill="none"
            >
              <path
                id="路径 1"
                fill-rule="evenodd"
                style="fill: #ffffff"
                transform="translate(-0.0025038818359259807 -0.000013710937484923136)  rotate(0 8.55717573120117 8.343633027343751)"
                opacity="1"
                d="M7.89,0.21C7.77,0.29 7.69,0.37 7.65,0.43L0.22,7.76C-0.07,8.04 -0.07,8.5 0.22,8.79C0.5,9.07 0.88,8.95 1.2,8.63L1.7,8.14L2.46,7.4C2.44,7.43 2.42,7.46 2.42,7.47L2.42,14.92C2.42,15.9 3.22,16.69 4.2,16.69L6.97,16.69L7.18,16.69L7.18,16.48L7.18,11.78C7.18,11.43 7.46,11.16 7.8,11.16L9.31,11.16C9.66,11.16 9.94,11.43 9.94,11.78L9.94,16.48L9.94,16.69L10.14,16.69L12.91,16.69C13.9,16.69 14.7,15.9 14.7,14.92L14.7,7.49L15.91,8.69C16.31,8.95 16.65,9.03 16.9,8.79C17.19,8.5 17.19,8.04 16.9,7.76L9.45,0.4C9.41,0.36 9.33,0.28 9.21,0.21C9.02,0.08 8.8,0 8.56,0C8.3,0 8.07,0.08 7.89,0.21Z "
              />
            </svg>
            <span>{{ t("topBar.homepage") }}</span>
            <div class="bottom-icon" style="margin-top: 10px;">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="44"
                  height="44" viewBox="0 0 24 24" stroke-width="3" stroke="#fff" fill="none" stroke-linecap="round"
                  stroke-linejoin="round" v-show="selected === 0">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="3 9 12 18 21 9"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="44"
                  height="44" viewBox="0 0 24 24" stroke-width="3" stroke="#fff" fill="none" stroke-linecap="round"
                  stroke-linejoin="round" v-show="selected === 1">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="3 15 12 6 21 15"/>
              </svg>
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/funcSafety' }">
          <div class="menu__item" >
            <svg
              t="1692612753776"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="18725"
              width="18"
              height="17"
            >
              <path
                d="M541.869057 133.095761C406.040867 201.979428 251.306027 245.125371 77.664538 262.401377L71.186036 196.646783H137.293202v366.674413H71.186036l64.961308-12.20779-64.961308 12.20779 64.961308-12.20779c7.359931 39.223585 28.822724 83.427243 64.344308 132.566904 73.290811 101.364321 182.587992 170.644631 327.8034 207.84093l-16.394577 64.035808-16.394577-64.035808 16.394577 64.035808-16.394577-64.035808c145.612051-37.284442 255.085518-104.801894 328.4204-202.552357 35.257155-46.980159 56.543663-89.024317 63.815451-126.04433l64.873166 12.780719H886.507749v-379.014418h66.107166l-6.566645 65.754595 6.566645-65.798666-6.566645 65.798666c-173.332989-17.276006-327.979686-60.377878-464.072305-129.349688l29.92451-58.967592zM481.931893 15.160577L511.900475 0l29.880439 15.160577c121.328685 61.523736 260.462234 100.042178 417.356574 115.68754L1018.722081 136.797762l-59.540521-5.949645L1018.722081 136.797762v445.29787l-1.234001 6.258145v0.044071c-11.150075 56.852163-40.413514 116.789326-87.790316 179.943706-91.933032 122.518614-225.733936 206.254358-401.402712 251.251302l-16.394577 4.186787 16.394577-4.186787-16.394577 4.186787-16.394577-4.186787c-176.10949-45.085087-310.174822-131.24476-402.151926-258.434948C46.28567 696.020314 17.198517 634.144007 6.224728 575.528986L5.07887 569.491199l1.145858 6.037787L5.07887 569.491199V136.797762l59.584592-5.949645C221.822231 115.246826 360.911708 76.684312 481.931893 15.204648z"
                fill="#FFFFFF"
                p-id="18726"
              ></path>
              <path
                d="M358.22335 420.177147l165.267915 146.757908-43.895158 49.44816-43.895158-49.44816 275.446524-244.596514 87.790317 98.89632-319.341683 283.555671-209.163073-185.717065z"
                fill="#FFFFFF"
                p-id="18727"
              ></path>
            </svg>
            <span>{{ t("topBar.funSafety") }}</span>
          </div>
        </router-link>

        <router-link to="/information_security">
          <div class="menu__item">
            <img src="../../assets/imgs/信息安全.png" alt="">
            <span>{{ t("topBar.informationSecurity") }}</span>
          </div>
        </router-link>

        <router-link to="/price">
          <div class="menu__item">
            <svg
              style="width: 1.6rem"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <defs>
                <rect id="path_0" x="0" y="0" width="20" height="20" />
              </defs>
              <g opacity="1" transform="translate(0 0)  rotate(0 10 10)">
                <mask id="bg-mask-0" fill="white">
                  <use xlink:href="#path_0" />
                </mask>
                <g mask="url(#bg-mask-0)">
                  <path
                    id="分组 1"
                    fill-rule="evenodd"
                    style="fill: #ffffff"
                    transform="translate(2.498855515863756 2.5160391210937334)  rotate(0 7.487186314408229 7.487176314333743)"
                    opacity="1"
                    d="M14.6 5.62L9.35 0.38C8.85 -0.13 8.03 -0.13 7.53 0.38L0.38 7.53C0.38 7.53 0.37 7.54 0.37 7.54C0.21 7.69 0.09 7.89 0.04 8.11C0 8.28 -0.01 8.46 0.01 8.64L0.01 13.82C0.01 14.45 0.52 14.96 1.15 14.96L6.34 14.96C6.51 14.99 6.69 14.98 6.86 14.93C7.09 14.88 7.29 14.76 7.44 14.6C7.44 14.6 7.44 14.6 7.44 14.6L14.6 7.44C15.1 6.94 15.1 6.12 14.6 5.62Z M11.5582 8.71178L6.25819 3.41178L1.51819 8.15178C1.48819 8.18178 1.45819 8.22178 1.43819 8.26178C1.32819 8.40178 1.26819 8.57178 1.26819 8.77178L1.26819 12.8518C1.26819 13.3218 1.64819 13.7118 2.11819 13.7118L6.20819 13.7118C6.40819 13.7118 6.58819 13.6418 6.72819 13.5318C6.75819 13.5118 6.78819 13.4818 6.81819 13.4518L11.5582 8.71178Z M13.2799 6.07178L8.89994 1.69178C8.64994 1.44178 8.23994 1.44178 7.97994 1.69178L6.70994 2.96178L12.0099 8.26178L13.2799 6.99178C13.5399 6.74178 13.5399 6.33178 13.2799 6.07178Z M3.11848 12.3075C3.50848 12.5275 3.98848 12.5275 4.36848 12.3075C4.75848 12.0875 4.99848 11.6775 4.99848 11.2275C4.99848 10.7775 4.75848 10.3675 4.36848 10.1475C3.98848 9.91745 3.50848 9.91745 3.11848 10.1475C2.73848 10.3675 2.49848 10.7775 2.49848 11.2275C2.49848 11.6775 2.73848 12.0875 3.11848 12.3075Z "
                  />
                </g>
              </g>
            </svg>
            <span>{{ t("topBar.price") }}</span>
          </div>
        </router-link>
        <router-link to="/about-us">
          <div class="menu__item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <defs>
                <rect id="path_0" x="0" y="0" width="16" height="16" />
              </defs>
              <g opacity="1" transform="translate(0 0)  rotate(0 8 8)">
                <mask id="bg-mask-0" fill="white">
                  <use xlink:href="#path_0" />
                </mask>
                <g mask="url(#bg-mask-0)">
                  <g
                    opacity="1"
                    transform="translate(0.0012173424389256837 -0.0000024334172321616165)  rotate(0 8.00039999254931 8.00000128356209)"
                  >
                    <path
                      id="路径 1"
                      fill-rule="evenodd"
                      style="fill: #ffffff"
                      transform="translate(7.2598075638110755 6.482998042792232)  rotate(0 0.728538828125 2.94270051953125)"
                      opacity="1"
                      d="M0,0.73L0,5.16C0,5.56 0.33,5.89 0.73,5.89C1.13,5.89 1.46,5.56 1.46,5.16L1.45,0.73C1.45,0.33 1.13,0 0.73,0C0.33,0 0,0.33 0,0.73Z "
                    />
                    <path
                      id="路径 2"
                      fill-rule="evenodd"
                      style="fill: #ffffff"
                      transform="translate(7.205626235686076 3.5678623335208606)  rotate(0 0.7832656015624999 0.7935186311480149)"
                      opacity="1"
                      d="M0,0.81C0,1.24 0.35,1.59 0.78,1.59C1.22,1.59 1.57,1.24 1.57,0.81L1.56,0.78C1.56,0.35 1.21,0 0.78,0C0.35,0 0,0.35 0,0.78L0,0.81Z "
                    />
                    <path
                      id="路径 3"
                      fill-rule="evenodd"
                      style="fill: #ffffff"
                      transform="translate(0 0)  rotate(0 8.00039999254931 8.00000128356209)"
                      opacity="1"
                      d="M2.4,11.57C2.4,11.28 2.22,11.01 1.94,10.9L2.06,10.78C0.65,7.79 1.68,4.21 4.47,2.43C7.27,0.65 10.95,1.23 13.06,3.78C15.18,6.33 15.06,10.06 12.79,12.47C10.52,14.88 6.81,15.23 4.14,13.28C3.93,13.06 3.62,12.99 3.34,13.1C3.06,13.21 2.87,13.48 2.88,13.78L2.88,13.84C2.88,14.17 3.1,14.46 3.42,14.54C4.76,15.49 6.36,16 8,16C11.6,16 14.76,13.59 15.72,10.12C16.67,6.64 15.18,2.96 12.08,1.12C8.98,-0.72 5.03,-0.26 2.44,2.25C-0.15,4.75 -0.75,8.68 0.98,11.84C1.08,12.19 1.42,12.41 1.78,12.36C2.14,12.31 2.41,12 2.4,11.64L2.4,11.57Z "
                    />
                  </g>
                </g>
              </g>
            </svg>
            <span>{{ t("topBar.aboutUs") }}</span>
          </div>
        </router-link>
        <el-dropdown>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="lang-icon"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#2c3e50"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="12" r="9" />
            <line x1="3.6" y1="9" x2="20.4" y2="9" />
            <line x1="3.6" y1="15" x2="20.4" y2="15" />
            <path d="M11.5 3a17 17 0 0 0 0 18" />
            <path d="M12.5 3a17 17 0 0 1 0 18" />
          </svg>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="switchLang('zh')"
                >简体中文</el-dropdown-item
              >
              <el-dropdown-item @click="switchLang('en')"
                >English</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </section>
  <div class="shouyefu" v-show="selected === 1" @mouseenter="selected = 1" @mouseleave="selected = 0">
    <div class="customer-case" :class="{'customer-case-en': lang === 'en'}" @mouseenter="selectedCustomer = 1" @mouseleave="selectedCustomer = 0">{{ t("topBar.clientCases") }}</div>
    <div class="automobile-tool-chain" :class="{'automobile-tool-chain-en': lang === 'en'}" @click="jumpAutomotiveToolchain">{{ t("topBar.automotiveToolchain") }}</div>
  </div>
  <div class="third-line" v-show="selected === 1" @mouseenter="selected = 1" @mouseleave="selected = 0">
    <div class="customer-case-information" :class="{'customer-case-information-en': lang === 'en'}" v-show="selectedCustomer === 1" @mouseenter="selected = 1;selectedCustomer = 1" @mouseleave="selected = 0;selectedCustomer = 0">
    <div class="content" :class="{'content-en': lang === 'en'}">
      <div class="one box" @click="jumpCustomerPage('aotecar')">
        <img src="../../assets/imgs/carIndustry1.png" alt="">
        <div class="company-name">{{ t("clientCase.oneName") }}</div>
      </div>
      <div class="two box" @click="jumpCustomerPage('TongyuAutomobile')">
        <img src="../../assets/imgs/carIndustry2.png" alt="">
        <div class="company-name">{{ t("clientCase.twoName") }}</div>
      </div>
      <div class="three box" @click="jumpCustomerPage('Fuerda')" style="margin-bottom: 1rem;">
        <img src="../../assets/imgs/carIndustry5.png" alt="">
        <div class="company-name">{{ t("clientCase.threeName") }}</div>
      </div>
      <!-- <div class="more">
        <div class="moretitle">更多客户案例</div>
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="10"
            height="9" viewBox="0 0 24 24" stroke-width="3" stroke="#2f64b5" fill="none" stroke-linecap="round"
            stroke-linejoin="round" v-show="selected === 1">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <polyline points="9 3 18 12 9 21"/>
        </svg>
      </div> -->
    </div>
  </div>
  </div>
</template>

<script setup lang="ts">
import useLocale from "@/hooks/useLocale"; // 国际化hook
import {ref} from 'vue'
import {useRouter,useRoute} from 'vue-router'
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
  changeLocale, // 修改国际化
} = useLocale();
// 切换语言
function switchLang(lang: string) {
  changeLocale(lang);
  localStorage.setItem("lang", lang);
}

// 判断是否鼠标移动到首页
const selected = ref(0);

// 判断是否鼠标移动到客户用例
const selectedCustomer = ref(0);

const router = useRouter();
// 跳转到客户页面
const jumpCustomerPage = (name: string) => {
  router.push({ path: `/customer_case/${name}` })
}

// 跳转到汽车工具链页面
const jumpAutomotiveToolchain = () => {
  router.push({path: '/automotive_toolchain'});
}

</script>

<style scoped lang="scss">
a:hover,
a:active {
  font-weight: 700;
}
.lang-icon {
  width: 2.4rem;
  height: 2.4rem;
  stroke: #fff;
  cursor: pointer;
}
</style>
